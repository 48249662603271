<template>
  <v-row
    class="pa-4 wrap"
  >
    <!-- TYPE OF BUDGET -->
    <v-col
      cols="12"
      v-if="activateTypeOfBudget"
    >
      <div>
        <TypeOfBudget
          :value="typeOfBudgetSaturation"
          @input="updateValue('typeOfBudgetSaturation', $event ? $event.toString().trim() : null)"
          :allowIndifferent="allowIndifferentTypeOfBudget"
          class="type-of-budget-select"
        >
        </TypeOfBudget>
      </div>
    </v-col>

    <v-col cols="12">
      <v-text-field
        class="invisible-input-slot-error-message"
        :error-messages="mustBeGreaterRules(highestAllowedCPM, lowestAllowedCPM, 'Highest allowed CPM', 'Lowest allowed CPM')"
        tab="tab1"
      >

      </v-text-field>
    </v-col>

    <!-- TYPE OF BUDGET SPECIAL INPUT MEDIAMATH / BEESWAX -->

    <v-col
      cols="12"
      v-if="activeTypeOfBudgetMarginCpm"
      class="elevation-2 type-of-budget-constraints"
    >
      <v-row>
        <v-col cols="3">
          <div>
            <PercentageTextField
              label="Expected margin daily (%)"
              :value="expectedMargin"
              @input="updateValue('expectedMargin', $event)"
              :rules="[rules.noNegativeValue, rules.numeric, rules.percentage]"
              tab="tab1"
            >

            </PercentageTextField>
          </div>
        </v-col>

        <v-col cols="12" class="offset offset-xs2 offset-sm2 offset-md2">
          <div>
            <v-text-field
              label="Rev Cpm"
              :value="revCPM"
              @input="updateValue('revCPM', $event)"
              :rules="[rules.noNegativeValue, rules.numeric]"
              class="no-spinners revcpm-typebudget-field"
              :type="`number`"
              tab="tab1"
            >
            </v-text-field>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-text-field
          class="invisible-input-slot-error-message"
          :error-messages="!showErrorMessageImpressions ? [] : ['fields must be filled as you chose \'Impressions\' as saturation metric']"
          tab="tab1"
        >

        </v-text-field>
      </v-row>

    </v-col>
    <!-- CONSTRAINTS FORM -->
    <v-col v-for="(item, index) in constraintsFormConfig" :key="index" cols="12">
      <ConstraintsFormNewFeatureReferenceWrapper
        v-if="item.computed !== 'expectedMargin' || (item.computed === 'expectedMargin' && requiredExpectedMarginDaily)"
        :model="getValueItem(item.computed)"
        :doubleModel="getValueItem(item.doubleComputed)"
        :label="item.label"
        :computed="item.computed"
        :doubleComputed="item.doubleComputed"
        :outline="outline"
        :isPercentage="item.isPercentage || (item.computed === 'proxyTargetKpiValue' && ['CTR','VTR'].indexOf(proxyTargetForOptimizedKpi) !== -1)"
        :ref="'constraint'"
        :hint="getHint(item)"
        :isDouble="item.isDouble"
        :disabled="getDisabled(item)"
        :disabled-no-constraints="getDisabledNoConstraints(item)"
        :untieValue="untie.value"
        :kpiToOptimizeValue="kpiToOptimizeOptions"
        :className="item.className"
        :currentKpiToOptimize="kpiToOptimize"
        :is-data-form="isDataForm"
        :requiredChoice="item.computed === 'expectedMargin' && requiredExpectedMarginDaily"
        :alerts-tips="getAlertsTips(item)"
        :feature-ref-config="getFeatureRefConfig(item)"
        :error-messages="getErrorMessagesForItem(item)"
        v-on:updateConstraints="updateValueItem"
        v-on:no-constraints-updated="$emit('no-constraints-updated')"
      >
      </ConstraintsFormNewFeatureReferenceWrapper>
    </v-col>

    <v-col v-if="showYoutubeMinA" cols="12">
      <v-col cols="7">
        <v-text-field
          id="youtubeMinAInput"
          label="Min. A"
          :value="youtubeMinA"
          @input="updateValue('youtubeMinA', $event)"
          :rules="[
            rules.numericAllowUndef,
            youtubeMinA != null && youtubeMinA.length !== 0 ? minACustomRule : true
          ]"
        />
      </v-col>
    </v-col>

    <v-col v-if="hasYoutubeMinA" cols="12" class="mb-3">
      <span class="warning-span">
        This IO is using a min A value, make sure there is an Even pacing at the IO level
      </span>
    </v-col>
    <v-col>
      <ThirdPartyConstraints
        v-if="activate3rdPartyConstraints"
        :insGetterSetter="insGetterSetter"
      >
      </ThirdPartyConstraints>
    </v-col>
    <!-- overwrite frequency constraints -->
    <v-col v-if="activateOverwriteFrequencyInput" cols="12">
      <OverwriteFrequencyInput
        v-on:updateMaxFrequency="updateMaxFrequency"
        v-on:updateOverwriteFrequency="updateOverwriteFrequency"
        :maxFrequencyProps="maxFrequencyValue"
        :overwriteFrequencyProps="overwriteFrequencyValue"
        ref="OverwriteFrequencyInput"
      >
      </OverwriteFrequencyInput>
    </v-col>

    <v-col cols="12" class="py-0">
      <WarningMessageBox
        :has-warning="displayWarningNotConform"
        :message-warning="messageBriefNotConfirm"
      >
      </WarningMessageBox>
    </v-col>

    <v-col cols="12" class="py-0">
      <WarningMessageBox
        :has-warning="displayWarningReachKpiOverwriteFrequency"
        :message-warning="messageReachKpiOverwriteFrequency"
      >
      </WarningMessageBox>
    </v-col>

    <v-col cols="12" class="py-0">
      <WarningMessageBox
        :has-warning="displayWarningReachAndFrequencyAndNoFrequencyCap"
        :message-warning="getMessageReachAndFrequencyAndNoFrequency('cap')"
      >
      </WarningMessageBox>
    </v-col>

    <v-col v-if="hasReachAndFrequency">
      <OverwriteFrequencyInput
        v-on:updateMaxFrequency="updateTargetFrequency"
        v-on:updateOverwriteFrequency="updateOverwriteFrequencyTarget"
        frequencyType="Target"
        :maxFrequencyProps="targetFrequencyValue"
        :overwriteFrequencyProps="overwriteFrequencyTargetValue"
        ref="OverwriteFrequencyTargetInput"
      >
      </OverwriteFrequencyInput>
    </v-col>

    <v-col cols="12" class="py-0">
      <WarningMessageBox
        :has-warning="displayWarningNotConformTarget"
        :message-warning="messageBriefNotConfirm"
      >
      </WarningMessageBox>
    </v-col>

    <v-col cols="12" class="py-0">
      <WarningMessageBox
        :has-warning="displayWarningReachAndFrequencyAndNoFrequencyTarget"
        :message-warning="getMessageReachAndFrequencyAndNoFrequency('target')"
      >
      </WarningMessageBox>
    </v-col>
  </v-row>
</template>

<script>
import { ConstraintsFormNewFeatureReferenceWrapper, OverwriteFrequencyInput, PercentageTextField, TypeOfBudget } from '../../FormFragments'
import { generalMixin } from '../../../../../mixins/generalMixin'
import { rulesMixin } from '../../../../../mixins/rulesMixin'
import { mapGetters } from 'vuex'
import {
  hasWarningFrequencyCap,
  hasWarningFrequencyTarget,
  warningPrefillMessage
} from '../../../../../../utils/BriefPrefiller/PrefillerWarning'
import WarningMessageBox from '@/components/TableComponents/Form/FormFragments/WarningMessageBox'
import InstructionGetterSetter from '@/models/Overlay/InstructionGetterSetter'
import MemberSettingModel from '@/models/Keystone_v2/MemberSettingModel'
import { perfFirstDevName } from '../../../../../../config/featureReferenceConstant'
import { checkPremepValidatorMixins } from '@/mixins/checkPremepValidatorMixins'
import ThirdPartyConstraints
  from '@/components/TableComponents/Form/FormFragments/3PObjectWatcher/ThirdPartyConstraints.vue'
import { activateMixin } from '@/mixins/activateMixin'

export default {
  name: 'ConstraintsToRespectBlock',
  components: {
    ConstraintsFormNewFeatureReferenceWrapper,
    TypeOfBudget,
    PercentageTextField,
    OverwriteFrequencyInput,
    WarningMessageBox,
    ThirdPartyConstraints
  },
  mixins: [generalMixin, rulesMixin, checkPremepValidatorMixins, activateMixin],
  data: function () {
    return {
      outline: false,
      displayWarningNotConform: false,
      displayWarningNotConformTarget: false,
      messageBriefNotConfirm: warningPrefillMessage,
      ottoMinVizError: `
       You cannot modify this field because <span style="font-weight: bold; text-transform: uppercase;">&nbsp;otto min viz&nbsp;</span> is activated.
       Please deactivate it (in the <span style="font-weight: bold; text-transform: uppercase;">&nbsp;instructions&nbsp;</span> tab) before modifying this field.
      `,
      perfFirstCpmValueError: `
       You cannot modify this field because <span style="font-weight: bold; text-transform: uppercase;">&nbsp;waterfall management&nbsp;</span> is set to perf_first.
       Please set it to delivery first before modifying this field.
      `,
      messageReachKpiOverwriteFrequency: 'For this KPI, managing frequency is a key part of the optimization strategy. Removing the overwrite will strongly impact the results negatively.'
    }
  },
  props: {
    typeOfBudgetSaturation: {
      type: [String],
      required: false
    },
    expectedMargin: {
      type: [String, Number],
      required: false
    },
    revCPM: {
      type: [String, Number],
      required: false
    },
    highestAllowedCPA: {
      type: [String, Number],
      required: false
    },
    proxyTargetKpiValue: {
      type: [String, Number],
      required: false
    },
    lowestAllowedCompletionRate: {
      type: [String, Number],
      required: false
    },
    lowestAllowedViewRate: {
      type: [String, Number],
      required: false
    },
    lowestAllowedCPM: {
      type: [String, Number],
      required: false
    },
    highestAllowedCPM: {
      type: [String, Number],
      required: false
    },
    proxyTargetForOptimizedKpi: {
      type: [String],
      required: false
    },
    untie: {
      type: [Object],
      required: false
    },
    kpiToOptimizeOptions: {
      type: [Object, Array],
      required: false
    },
    kpiToOptimize: {
      type: [String],
      required: false
    },
    maxFrequencyValue: {
      type: [Object],
      required: false
    },
    targetFrequencyValue: {
      type: [Object],
      required: false
    },
    overwriteFrequencyValue: {
      type: [Boolean],
      required: false
    },
    overwriteFrequencyTargetValue: {
      type: [Boolean],
      required: false
    },
    forceMinBudget: {
      type: [Boolean],
      required: false
    },
    isDataForm: {
      type: [Boolean],
      required: false,
      default: false
    },
    isOttoMinVizActive: {
      type: Boolean,
      required: true,
      default: false
    },
    requiredExpectedMarginDaily: {
      type: Boolean,
      required: false,
      default: false
    },
    overwriteUnknownDemographic: {
      type: String,
      required: false
    },
    waterfallPriority: {
      type: [String],
      required: false
    },
    proxyTargetKpiValue3rdParty: {
      type: [String, Number],
      required: false
    },
    originalInstructionGetterSetter: {
      type: [InstructionGetterSetter],
      required: true
    },
    keystoneMemberSetting: {
      type: MemberSettingModel,
      required: true
    },
    youtubeMinA: {
      type: [String, Number],
      required: false
    },
    targetKpiObject: {
      type: Object,
      required: false
    },
    insGetterSetter: {
      type: InstructionGetterSetter,
      required: true
    }
  },
  methods: {
    /**
     * @param property {String}
     * @param newValue
     * @param isBoolean
     */
    updateValue (property, newValue, isBoolean = false) {
      if (isBoolean) {
        newValue = Boolean(newValue)
      }
      this.$emit(`update:${property}`, newValue)
    },
    getValueItem (property) {
      return this[property]
    },
    updateValueItem (computed, value, noConstraints) {
      this.$emit('updateConstraints', computed, value, noConstraints)
    },
    /**
     * @param newValue {MaxFrequency}
     */
    updateMaxFrequency (newValue) {
      this.$emit('updateMaxFrequency', newValue)
      this.checkHasWarningNotConformBrief(this.overwriteFrequencyValue, newValue)
    },
    updateTargetFrequency (newValue) {
      this.$emit('updateTargetFrequency', newValue)
      this.checkHasWarningNotConformTargetBrief(this.overwriteFrequencyTargetValue, newValue)
    },
    updateOverwriteFrequency (newValue) {
      this.$emit('updateOverwriteFrequency', newValue)
    },
    updateOverwriteFrequencyTarget (newValue) {
      this.$emit('updateOverwriteFrequencyTarget', newValue)
    },
    /**
     * reset the constraint check box to false
     */
    resetConstraints () {
      for (let i in this.$refs.constraint) {
        this.$refs.constraint[i].resetConstraint()
      }
    },
    /**
     * @param overwriteFrequency
     * @param maxFrequency {MaxFrequency}
     * @returns {boolean|boolean}
     */
    checkHasWarningNotConformBrief (overwriteFrequency, maxFrequency) {
      this.$nextTick(() => {
        this.displayWarningNotConform = this.hasWarningNotConformBrief(overwriteFrequency, maxFrequency)
      })
    },
    /**
     * @param overwriteFrequency
     * @param maxFrequency {MaxFrequency}
     * @returns {boolean|boolean}
     */
    checkHasWarningNotConformTargetBrief (overwriteFrequency, maxFrequency) {
      this.$nextTick(() => {
        this.displayWarningNotConformTarget = this.hasWarningNotConformBriefTarget(overwriteFrequency, maxFrequency)
      })
    },
    /**
     * @param overwriteFrequency
     * @param maxFrequency {MaxFrequency}
     * @returns {boolean}
     */
    hasWarningNotConformBrief (overwriteFrequency, maxFrequency) {
      /**
       * @type {BriefModel}
       */
      const brief = this.$store.getters.getCurrentTraderBrief
      if (!brief) {
        return false
      }
      return hasWarningFrequencyCap(brief, maxFrequency, overwriteFrequency)
    },
    hasWarningNotConformBriefTarget (overwriteFrequency, maxFrequency) {
      /**
       * @type {BriefModel}
       */
      const brief = this.$store.getters.getCurrentTraderBrief
      if (!brief) {
        return false
      }
      return hasWarningFrequencyTarget(brief, maxFrequency, overwriteFrequency)
    },
    getHint (item) {
      if (item.hint) {
        return item.hint
      }
      if (item.computed === 'proxyTargetKpiValue3rdParty') {
        if (this.getValueItem(item.computed)) {
          return `
          This value is not yet taken into account by the prod. Ensure you have a KPI target DSP proxy.
          `
        }
      } else if (item.computed === 'expectedMargin') {
        if (this.isWarningCPMValidator()) {
          const errors = this.errorMessageCheckPreMepExpectedMargin('warning')
          return errors.length > 0 ? errors.join(' ') : ''
        }
      }
      return null
    },
    getDisabled (item) {
      if (item.isDisabled) {
        return item.isDisabled
      }

      if (this.checkCpmValuePerfFirstError(item)) {
        return true
      }
      return false
    },
    getDisabledNoConstraints (item) {
      if (this.checkCpmValuePerfFirstError(item)) {
        return true
      } else if (this.checkIfOttoMinVizError(item)) {
        return true
      }
      return false
    },
    getDisabledConstraintsPerfFirst () {
      return [
        'highestAllowedCPM',
        'lowestAllowedCPM'
      ]
    },
    checkCpmValuePerfFirstError (item) {
      // perf_first rule
      const disabledConstraints = this.getDisabledConstraintsPerfFirst()
      return Boolean(disabledConstraints.includes(item.computed) &&
        this.waterfallPriority === 'perf_first' && !this.getValueItem(item.computed))
    },
    checkIfOttoMinVizError (item) {
      return Boolean(item.computed === 'lowestAllowedViewRate' && this.isOttoMinVizActive)
    },
    getAlertsTips (item) {
      if (this.checkIfOttoMinVizError(item)) {
        return {
          content: this.ottoMinVizError,
          type: 'error'
        }
      } else if (this.checkCpmValuePerfFirstError(item)) {
        return {
          content: this.perfFirstCpmValueError,
          type: 'error'
        }
      }
      return null
    },
    /**
     * @param item
     * @returns {FeatureRefConfig}
     */
    getFeatureRefConfig (item) {
      if (item.computed === 'proxyTargetKpiValue3rdParty') {
        return {
          nfrDevName: perfFirstDevName,
          application: 'member',
          entityId: this.keystoneMemberSetting.id,
          dsp: this.dsp
        }
      }

      return null
    },
    getErrorMessagesForItem (item) {
      let errors = []
      if (['lowestAllowedCPM', 'highestAllowedCPM'].includes(item.computed)) {
        let value = this.getValueItem(item.computed)
        errors.push(this.minimumValue(value, 0, true))
      } else if (item.computed === 'expectedMargin') {
        if (!this.isWarningCPMValidator()) {
          errors = [...this.errorMessageCheckPreMepExpectedMargin('error'), ...errors]
        }
      }
      return errors
    },
    errorMessageCheckPreMepExpectedMargin (messageType) {
      // we display the error message only constraints_io.margin_daily inherit from expected margin
      // Otherwise, the error is only displayed in the constraints_io.margin_daily field
      if (this.$store.getters.getInheritFromExpectedMargin) {
        const res = this.getMessageRelatedToFieldCPMValidator(['constraints_io.margin_daily'], messageType)
        if (res) {
          return res
        }
      }
      return []
    },
    /**
     * @param capOrTarget {string} should be cap or target
     * @returns {string}
     */
    getMessageReachAndFrequencyAndNoFrequency (capOrTarget) {
      const allowedValues = ['cap', 'target']
      if (!allowedValues.includes(capOrTarget)) {
        throw new Error('capOrTarget should be cap or target')
      }

      return `For this KPI, managing the frequency ${capOrTarget} is a key part of the optimization strategy.
      Removing the overwrite will strongly impact the results negatively.`
    }
  },
  computed: {
    ...mapGetters(['isUserDebugger']),
    activateTypeOfBudget () {
      return [this.$MEDIAMATH, this.$THETRADEDESK, this.$BEESWAX, this.$KAYZEN].indexOf(this.dsp) !== -1
    },
    activateOverwriteFrequencyInput () {
      return [this.$MEDIAMATH, this.$THETRADEDESK, this.$DBM, this.$BEESWAX, this.$YOUTUBE, this.$APPNEXUS, this.$META, this.$GOOGLEADS].indexOf(this.dsp) !== -1
    },
    showErrorMessageImpressions () {
      return !this.revCPM || !this.expectedMargin
    },
    constraintsFormConfig () {
      if (Object.keys(this.$dspConfig[this.dsp]).indexOf('constraintsFormConfig') === -1) {
        console.warn(`constraintsFormConfig is not a key of dspConfig for the dsp ${this.dsp}. No constraints to respect input will be displayed.`)
        return []
      }
      return this.$dspConfig[this.dsp].constraintsFormConfig
    },
    dspHasTypeOfBudgetMarginCpm () {
      return [this.$BEESWAX, this.$MEDIAMATH, this.$THETRADEDESK].indexOf(this.dsp) !== -1
    },
    activeTypeOfBudgetMarginCpm () {
      return this.dspHasTypeOfBudgetMarginCpm && this.typeOfBudgetSaturation === 'imp'
    },
    allowIndifferentTypeOfBudget () {
      return [this.$BEESWAX, this.$MEDIAMATH].indexOf(this.dsp) === -1
    },
    displayWarningReachKpiOverwriteFrequency () {
      return this.kpiToOptimize === 'reach' && this.overwriteFrequencyValue === false
    },
    displayWarningReachAndFrequencyAndNoFrequencyCap () {
      return this.kpiToOptimize === 'reach_and_frequency' && (this.overwriteFrequencyValue === false)
    },
    displayWarningReachAndFrequencyAndNoFrequencyTarget () {
      return this.kpiToOptimize === 'reach_and_frequency' && (this.overwriteFrequencyTargetValue === false)
    },
    showYoutubeMinA () {
      return this.dsp === this.$YOUTUBE
    },
    hasYoutubeMinA () {
      return this.dsp === this.$YOUTUBE && (this.youtubeMinA != null && this.youtubeMinA !== '')
    },
    minACustomRule () {
      const msgs = [
        'You must specify a target KPI if you want to apply a Min A value',
        'The min A value cannot be more than 3 times higher than the target KPI'
      ]
      const rules = [
        [(this.targetKpiObject.KPI != null && this.targetKpiObject.KPI.length !== 0), msgs[0]],
        [(this.targetKpiObject.value != null && this.targetKpiObject.value.length !== 0), msgs[0]],
        [((this.targetKpiObject.value != null && this.targetKpiObject.value.length !== 0) && this.youtubeMinA <= this.targetKpiObject.value * 3), msgs[1]]
      ]
      for (const rule of rules) {
        if (!rule[0]) {
          return rule[1]
        }
      }
      return true
    },
    hasReachAndFrequency () {
      return this.kpiToOptimize === 'reach_and_frequency'
    }
  },
  watch: {
    typeOfBudgetSaturation: {
      handler: function (newValue) {
        if (!this.dspHasTypeOfBudgetMarginCpm) {
          return
        }

        if (newValue === 'money') {
          this.updateValue('expectedMargin', '')
          this.updateValue('revCPM', '')
        }
      }
    }
  }
}
</script>

<style scoped>
.warning-span {
  color: orange;
}
</style>
