import { RulesChecker } from './RulesChecker'
import { $APPNEXUS, $BEESWAX, $DBM, $FACEBOOK, $MEDIAMATH, $THETRADEDESK, $YOUTUBE } from '../../config/dspConfig'
import { ThirdPartyKpiHelper } from '../ThirdPartyKpi/thirdPartyKpiHelper'

class BriefRulesChecker extends RulesChecker {
  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {string}
   * @return {string|boolean}
   */
  errorKpiToOptimizeMaxA (editedItem, instructionContainer, dsp) {
    if ([$MEDIAMATH, $APPNEXUS, $DBM].indexOf(dsp) === -1) {
      return false
    }

    if (this._isset(editedItem.constraints_io) && this._isset(editedItem.constraints_io.max_A) &&
      ['CPC', 'CPA', 'CPCV', 'CPA_PC'].indexOf(editedItem.true_KPI_to_optimize) === -1) {
      return `-You selected a "max A", with a <strong>${editedItem.true_KPI_to_optimize}</strong> KPI, those are not compatible, either change max A or your KPI.`
    }
    return false
  }

  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {string}
   * @return {string|boolean}
   */
  errorExpectedMarginMaxA (editedItem, instructionContainer, dsp) {
    if ([$MEDIAMATH, $APPNEXUS, $DBM].indexOf(dsp) === -1) {
      return false
    }
    if (this._isset(editedItem.constraints_io) && this._isset(editedItem.constraints_io.max_A) && this._isset(editedItem.constraints_io.margin_daily)) {
      return `-You selected an "expected margin" with a "max A" which are not compatible, please remove max A if your revenue type is rev_CPM or rev_CPC.`
    }
    return false
  }

  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {string}
   * @return {string|boolean}
   */
  errorKpiToOptimizeMaxCpm (editedItem, instructionContainer, dsp) {
    if ([$APPNEXUS, $MEDIAMATH, $DBM, $THETRADEDESK, $BEESWAX].indexOf(dsp) === -1) {
      return false
    }

    if (['CTR', 'VTR', 'authentic_attention_index'].indexOf(editedItem.true_KPI_to_optimize) !== -1 && !this.issetInObject(editedItem, 'constraints_io.max_CPM') &&
      !this.issetInObject(editedItem, 'constraints_io.margin_daily')) {
      return `-You selected <strong>${editedItem.true_KPI_to_optimize}</strong> without entering a <strong>highest allowed CPM</strong> nor an <strong>expected margin</strong> constraint.`
    }
    return false
  }

  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {string}
   * @return {string|boolean}
   */
  errorCpmSaturationImp (editedItem, instructionContainer, dsp) {
    if ((this.issetInObject(editedItem, 'obj_watcher.saturation.budget_type') && editedItem.obj_watcher.saturation.budget_type === 'imp') &&
      (this.issetInObject(editedItem, 'obj_watcher.max_CPM.value') || this.issetInObject(editedItem, 'obj_watcher.min_CPM.value'))) {
      return `You can't have a <strong>min or max Allowed CPM</strong> constraints with a <strong>rev_CPM</strong> campaign, it already has inherent constraints based on its margin objective`
    }
  }

  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {string}
   * @return {string|boolean}
   */
  errorTargetNotSetAndMaxASetAndCpa (editedItem, instructionContainer, dsp) {
    if (this.issetInObject(editedItem, 'constraints_io.max_A') &&
      ['CPA', 'CPA_PC'].indexOf(editedItem.true_KPI_to_optimize) !== -1 &&
      !this.issetInObject(editedItem, 'obj_watcher.true_KPI_to_optimize.value')) {
      return `You selected a "max A", with a ${editedItem.true_KPI_to_optimize} KPI and without a Targeted ${editedItem.true_KPI_to_optimize} value,
      please add a Targeted ${editedItem.true_KPI_to_optimize} value.`
    }
  }

  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {string}
   * @return {string|boolean}
   */
  errorHighestAllowedCpmAndMargin (editedItem, instructionContainer, dsp) {
    if (![$DBM, $APPNEXUS].includes(dsp)) {
      return false
    }

    if (this.issetInObject(editedItem, 'constraints_io.max_CPM') &&
      this.issetInObject(editedItem, 'constraints_io.margin_daily')) {
      return 'You can\'t fill in both a Margin and a Max_CPM constraints, please chose either one of them.'
    }
  }

  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {string}
   * @return {string|boolean}
   */
  errorNoCustomAlgoRevCpm (editedItem, instructionContainer, dsp) {
    if (!($DBM === dsp)) {
      return false
    }

    if (editedItem.revenue_type === 'rev_cpm' && editedItem.use_custom_algo) {
      return 'Custom algo is not yet available for rev_CPM IOs, please use bid multipliers instead'
    }

    return false
  }

  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {string}
   * @return {string|boolean}
   */
  errorWaterfallPriorityKpiToOptimize (editedItem, instructionContainer, dsp) {
    if ($FACEBOOK === dsp) {
      return false
    }

    if (['CTR', 'VTR'].includes(editedItem.true_KPI_to_optimize) && editedItem.waterfall_priority === 'perf_first') {
      return 'You have select a KPI not compatible with <strong>Performance First</strong>'
    }
    return false
  }

  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {string}
   * @return {string|boolean}
   */
  errorWaterfallPriorityKpiValue (editedItem, instructionContainer, dsp) {
    if ($FACEBOOK === dsp) {
      return false
    }

    if (editedItem.waterfall_priority === 'perf_first' &&
      (!this.issetInObject(editedItem, 'KPI_rolling_period') ||
        !this.issetInObject(editedItem, 'obj_watcher.true_KPI_to_optimize.value'))) {
      return `
       You have select <strong>Performance First</strong> without specifying the <strong>KPI Target (DSP)</strong>
       nor the <strong>KPI Rolling Period</strong>.
      `
    }
    return false
  }

  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {string}
   * @return {string|boolean}
   */
  errorWaterfallPriorityMarkupModule (editedItem, instructionContainer, dsp) {
    if ($FACEBOOK === dsp) {
      return false
    }

    if (editedItem.waterfall_priority === 'perf_first' && editedItem.markup_management_is_inherited_from_seat) {
      return `
        <strong>Performance first</strong> is not available when the <strong>markup module</strong> is inherited from the seat at the moment.
        If you have a use case where you need to use both features combined, kindly contact the tech team.
      `
    }

    if (editedItem.waterfall_priority === 'perf_first' &&
        this.issetInObject(editedItem, 'markup_module') &&
        this.issetInObject(editedItem, 'markup_module.state') &&
        editedItem.markup_module.state === 'custom'
    ) {
      return `
        <strong>Performance first</strong> is not available when the <strong>markup module</strong> is activated at the moment.
        If you have a use case where you need to use both features combined, kindly contact the tech team.
      `
    }
    return false
  }

  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {string}
   * @return {string|boolean}
   */
  errorYoutubeForActionOverwriteBid (editedItem, instructionContainer, dsp) {
    if (dsp === $YOUTUBE && editedItem.overwrite_bid_adjustments_on_device === true && editedItem.true_KPI_to_optimize === 'CPA') {
      return `The ‘Overwrite bid adjustments on device’ option must be turned off for CPA KPI on Youtube`
    }
    return false
  }

  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {string}
   * @return {string|boolean}
   */
  errorThirdPartyKpiAlphaParam (editedItem, instructionContainer, dsp) {
    if (editedItem?.p3_obj_watcher?.kpi_type && (editedItem?.p3_obj_watcher_alpha == null || editedItem?.p3_obj_watcher_alpha === '')) {
      return 'Please fill the 3rd Party Constraint Alpha Param in the Algo strategy part, it’s mandatory when using a 3rd party KPI as a constraint'
    }
    return false
  }

  errorThirdPartyConstraintsAndThirdPartyKpi (editedItem, instructionContainer, dsp) {
    const kpiHelper = new ThirdPartyKpiHelper()
    const isThirdPartyKpi = kpiHelper.isKpiSecondLvl(editedItem.true_KPI_to_optimize)
    if (isThirdPartyKpi && editedItem?.p3_obj_watcher?.kpi_type) {
      return 'You\'ve selected a 3rd party constraint with a 3rd party KPI, this combination is not yet available, please change your setup'
    }

    return false
  }

  /**
   * @param editedItem {import('../../types/instruction_type').IoBrief}
   * @param instructionContainer {Instruction[]}
   * @param dsp {InstructionDsp}
   * @return {string|boolean}
   */
  errorMinAMaxA (editedItem, instructionContainer, dsp) {
    if (dsp !== $YOUTUBE) {
      return false
    }

    const minA = editedItem.constraints_io?.min_A
    const maxA = editedItem.constraints_io?.max_A

    if (minA && maxA && minA > maxA) {
      return 'The min A value is higher than the max A value, please correct this.'
    }

    if (maxA && maxA <= 0) {
      return 'The max A value is equal or lower than 0, please correct this.'
    }

    return false
  }
}

export {
  BriefRulesChecker
}
